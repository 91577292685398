import RImageSlider from "./RImageSlider";
const RSlider = () => {
  const slides = [
    { url: "image/2responivehome.svg", title: "forest" },
    { url: "image/responivehome.svg", title: "beach" },
    // { url: "image/Rgoldpremium.jpg", title: "boat" },
   
    // { url: "image/slide4.svg", title: "forest" },
    // { url: "image/slide3.svg", title: "forest" },
    // { url: "http://localhost:3000/image-4.jpg", title: "city" },
    // { url: "http://localhost:3000/image-5.jpg", title: "italy" },
  ];
  const containerStyles = {
    width: "100%",
    height: "400px",
    margin: "0 auto",
  };
  return (
    <div>
  
      <div style={containerStyles}>
        <RImageSlider slides={slides} />
      </div>
    </div>
  );
};

export default RSlider;
