import React from 'react'
// import Carousel from '../Carousel/Carousel'
import Form from '../Form/Form'

function Gallery() {
  return (
    <div >

      <section>
        <div className='w-full h-[380px]' style={{ backgroundImage: 'url("./image/gg1.jpg")' }} >
          <h1 className='text-5xl font-myFont text-white pt-40 pl-10 font-bold xs:text-center'>Our Gallery</h1>
        </div>
        <div className='h-20  ' style={{ backgroundImage: 'url("./image/allprodcut.svg")' }} >
                <h1 className='text-3xl p-4 font-serif text-black font-bold text-center'>We Are Here To Serve You Deliciuos Product</h1>
            </div>

        {/* <section class="overflow-hidden my-6 text-gray-700">
          <div class="container px-5 py-2 mx-auto  lg:px-32">
            <div class="flex flex-wrap -m-1 md:-m-2">
              <div class="flex flex-wrap w-1/2">
                <div class="w-1/2 p-1 md:p-2">
                  <img alt="gallery" class="block object-cover object-center w-full h-full cursor-pointer hover:scale-110 transition duration-500 ease-in-out  rounded-lg"
                    src="image/dg1.svg" />
                </div>
                <div class="w-1/2 p-1 md:p-2">
                  <img alt="gallery" class="block object-cover object-center w-full h-full cursor-pointer hover:scale-110 transition duration-500 ease-in-out  rounded-lg"
                    src="image/dg2.svg" />
                </div>
                <div class="w-full p-1 md:p-2">
                  <img alt="gallery" class="block object-cover object-center w-full h-full  rounded-lg"
                    src="image/dg3.svg" />
                </div>
              </div>
              <div class="flex flex-wrap w-1/2">
                <div class="w-full p-1 md:p-2">
                  <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                    src="image/dg4.svg" />
                </div>
                <div class="w-1/2 p-1 md:p-2">
                  <img alt="gallery" class="block object-cover object-center w-full h-full cursor-pointer hover:scale-110 transition duration-500 ease-in-out  rounded-lg"
                    src="image/dg5.svg" />
                </div>
                <div class="w-1/2 p-1 md:p-2">
                  <img alt="gallery" class="block object-cover object-center w-full h-full cursor-pointer hover:scale-110 transition duration-500 ease-in-out  rounded-lg"
                    src="image/dg6.svg" />
                </div>
              </div>
            </div>
          </div>
        </section> */}


        {/* <div className='h-[360px] xs:grid-cols-1  xs:h-[200px] grid grid-cols-8 bg-[#EEEEEE]'>
          <div className=' xs:hidden col-span-2 p-10'>
            <img src='image/drinkkk g.svg'></img>
          </div>
          <div className='col-span-4'>
            <h1 className='text-3xl xs:mt-10 mt-20 xs:text-lg text-[#8C8C8C]  text-center'>Satisfying people’s hunger for life’s simple pleasures. Make people happy, have some fun and be number one. The pleasure of finding the difference.</h1>
          </div>
          <div className=' xs:hidden col-span-2 '>
            <img src='image/mix g.svg'></img>
          </div>
        </div> */}
        <section> <div className=" container  xs:mt-16 mx-auto lg:px-20  py-6 grid gap-4 lg:grid-cols-4">
          <img
            src="image/dg7.jpg"
            className="w-full h-full col-span-2 row-span-2 rounded shadow-sm lg:col-start-3 lg:row-start-1 cursor-pointer hover:scale-110 transition duration-500 ease-in-out "
          />
          <img
            className="w-full h-full cursor-pointer hover:scale-110 transition duration-500 ease-in-out "
            src="image/dg8.jpg"
          />
          <img
            className="w-full h-full  cursor-pointer hover:scale-110 transition duration-500 ease-in-out"
            src="image/dg9.jpg"
          />
          <img
            className="w-full h-full cursor-pointer hover:scale-110 transition duration-500 ease-in-out"
            src="image/dg1.jpg"
          />
          <img
            className="w-full h-full cursor-pointer hover:scale-110 transition duration-500 ease-in-out"
            src="image/dg10.jpg"
          />
        </div></section>
      </section>
    </div>
  )
}

export default Gallery