import React from 'react'

function Footer() {
    return (

        <div style={{ backgroundImage: 'url("./image/foot.png")' }}  >
            <div className='grid md:grid-cols-12 xs:col-span-1 xs:mx-8  py-20 mt-28 ml-48'>
                <div className=' text-white   col-span-4'>
                    <h1 className='text-2xl font-myFont  font-bold'>About Us</h1>
                    <div className=' w-20 my-4 border-2  border-red-700'></div>
                    <p className='w-[350px] xs:w-[280px]  '>Everything we do is a matter of heart, body and soul. Our mission is to provide sustainably sourced, hand-picked, quality coffee.</p>
                    <div className='ml-0 gap-4 mt-10 xs:p-8 flex'>
                        <img src='image/fbsoc.svg'></img>
                        <img src='image/instsoc.svg'></img>
                        <img src='image/insttwit.svg'></img>
                    </div>
                </div>
                <div className='col-span-4  xs:mt-8 text-white '>
                    <h1 className='text-2xl font-myFont font-bold'>Contact  Us</h1>
                    <div className=' w-20 my-4 border-2 border-red-700'></div>
                    <p className='w-[350px] xs:w-[280px] '>DEEPZI HERBAL (OPC ) PRIVATE LIMITED OFFICE NO. -220, AT. POST - DAREGAON, TQ. DIST.- JALNA- 431203 MAHARASHTRA INDIA</p>
                    <p className='mt-2'>Customer Care: +91 8830253302</p>
                    <p className='mt-2'>Email: contact@deepzitea.com</p>
                </div>
                <div className='col-span-4 xs:hidden text-white '>
                    <h1 className='text-2xl font-myFont font-bold'>Instagram Feed</h1>
                    <div className=' w-20 my-4 border-2 border-red-700'></div>
                    <div className='ml-0 gap-4 mt-10 xs:p-8 flex'>
                        <img src='image/instagram-2.jpg ' className='rounded-3xl' alt='pro'></img>
                        <img src='image/instagram-3.jpg ' className='rounded-3xl' alt='pro'></img>
                        <img src='image/instagram-4.jpg ' className='rounded-3xl' alt='pro'></img>
                        <img src='image/coffee-3.jpg  ' className='rounded-3xl xs:hidden' alt='pro'></img>

                    </div>
                    <div className='ml-0 gap-4 mt-6 xs:p-8 flex'>
                        <img src='image/instagram-3.jpg ' className='rounded-3xl' alt='pro'></img>
                        <img src='image/coffee-1.jpg ' className='rounded-3xl' alt='pro'></img>
                        <img src='image/instagram-2.jpg ' className='rounded-3xl' alt='pro'></img>
                        <img src='image/instagram-4.jpg ' className='rounded-3xl xs:hidden' alt='pro'></img>

                    </div>
                </div>
                
            </div>
            <h2 className=' pb-10 text-center  text-white'>© 2023 Deepzi premium | Designed & developed by <a className='font-bold text-red-600'  href='https://5techg.com/'>5TechG Lab</a></h2>
        </div>
    )
}

export default Footer