import React from 'react'
import { useEffect } from 'react';

function Contact() {

    useEffect(() => {
        const ifameData = document.getElementById("iframeId")
        const lat = 19.841154;
        const lon = 75.232559;
        ifameData.src = `https://maps.google.com/maps?q=${lat},${lon}&hl=es;&output=embed`
    })

    return (
        <div >
            <section>
                <div className=''>
                    {/* banner image */}
                    <div className='w-full h-[380px]' style={{ backgroundImage: 'url("./image/1contact.jpg")' }} >
                        <h1 className='text-5xl font-myFont text-white pt-40 pl-10 font-bold xs:text-center'>Contact Us</h1>
                    </div>
                    <section>
                        <div className='grid my-20 xs:ml-4 xs:grid-cols-1 ml-40 grid-cols-3'>
                            <div className='border text-center xs:mb-4 font-myFont font-bold w-80 h-80' >
                                <img className='mx-auto pt-8' src='image/1call.svg'></img>
                                <h2 className='text-4xl text-red-800 pt-2' >Call us </h2>
                                <h2 className='pt-4 text-lg text-slate-600'>+  91 8830253302 </h2>
                               
                            </div>
                            <div className='border text-center xs:mb-4 font-myFont font-bold w-80 h-80' >
                                <img className='mx-auto pt-8' src='image/1mail.svg'></img>
                                <h2 className='text-4xl pt-2  text-red-800' >Mail us </h2>
                                <h2 className='pt-4 text-lg text-slate-600'> contact@deepzitea.com</h2>
                            </div>
                            <div className='border text-center font-myFont font-bold w-80 h-80' >
                                <img className='mx-auto pt-8 ' src='image/1location.svg'></img>
                                <h2 className='text-4xl pt-2  text-red-800' >Location </h2>
                                <h2 className='pt-4 text-lg text-slate-600'>Deepzi Herbal (OPC ) Pvt. Ltd. Office No. -220, at. Post - Daregaon, district- Jalna- 431203 Maharastra India </h2>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className=' ml-40 xs:ml-0 mt-10 xs:grid-cols-1 grid grid-cols-6'>
                            <div className='col-span-2'>
                                <img src='image/1about.svg'>
                                </img>
                            </div>
                            <div className='xs:mt-28 xs:-ml-8 mt-16 col-span-4'>
                                <h1 className='font-myFont font-bold ml-28  text-red-800 xs:text-4xl text-5xl'>Get in Touch</h1>
                                <div>
                                    <div class="flex  ml-28">
                                        <div className="relative mt-6 mb-3 xl:w-96" data-te-input-wrapper-init>
                                            <input
                                                type="text"
                                                className="peer block min-h-[auto] w-full border-b-4 border-black bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                                placeholder="Enter Your Name" />
                                            <label
                                                className="pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-neutral-200"
                                            > Your Name
                                            </label>
                                        </div>
                                    </div>
                                    <div class="flex  ml-28">
                                        <div className="relative mt-4 mb-3 xl:w-96" data-te-input-wrapper-init>
                                            <input
                                                type="text"
                                                className="peer block min-h-[auto] w-full border-b-4 border-black bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                                placeholder="Enter Your Name" />
                                            <label
                                                className="pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-neutral-200"
                                            >Phone
                                            </label>
                                        </div>
                                    </div>
                                    <div class="flex  ml-28">
                                        <div className="relative mt-6 mb-3 xl:w-96" data-te-input-wrapper-init>
                                            <input
                                                type="text"
                                                className="peer block min-h-[auto] w-full border-b-4 border-black bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                                placeholder="Enter Your Name" />
                                            <label
                                                className="pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-neutral-200"
                                            >Massage
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button className=' xs:w-52 ml-28 mt-4 h-10 bg-[#D9D9D9] text-black font-myFont text-lg font-bold hover:bg-red-800 hover:text-white border-2 w-[380px]'>Send</button>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className='mt-40 '>
                        <iframe id="iframeId" height="500px" width="100%"></iframe>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contact