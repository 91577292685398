export const Categories = [
  {
    id: 1,
    categoryId: 1,
    category: 'Deppzi premium',
    title: "Mint Green Tea",
    image: '/image/Mint Green Tea.jpg',
    cName: 'nav-text'
  }, {
    id: 2,
    categoryId: 1,
    category: 'Deppzi premium',
    title: "Honey Green Tea",
    image: '/image/Honey Green Tea.svg',
    cName: 'nav-text'
  }, {
    id: 3,
    categoryId: 1,
    category: 'Deppzi premium',
    title: "Ginger Green Tea",
    image: '/image/goldcc.png',
    cName: 'nav-text'
  }, {
    id: 4,
    categoryId: 1,
    category: 'Deppzi premium',
    title: "Lemon Green Tea",
    image: '/image/Lemon Green Tea.svg',
    cName: 'nav-text'
  }, {
    id: 5,
    categoryId: 1,
    category: 'Deppzi premium',
    title: "Mint Green Tea",
    image: '/image/mintGreen Tea.png',
    cName: 'nav-text'
  }, {
    id: 6,
    categoryId: 2,
    category: 'Deppzi Gold',
    title: "Black Tea",
    image: '/image/deepp5.jpg',
    cName: 'nav-text'
  }, {
    id: 7,
    categoryId: 2,
    category: 'Deppzi Gold',
    title: " Chocolate Tea",
    image: '/image/Chocolate Tea.svg',
    cName: 'nav-text'
  }, {
    id: 8,
    categoryId: 2,
    category: 'Deppzi Gold',
    title: "Itelian Rosted Cofee",
    image: '/image/deepp7.jpg',
    cName: 'nav-text'
  }, {
    id: 9,
    categoryId: 2,
    category: 'Deppzi Gold',
    title: "Gold Tea",
    image: '/image/golddeep.jpg',
    cName: 'nav-text'
  }, {
    id: 10,
    categoryId: 2,
    category: 'Deppzi Gold',
    title: "Ginger Green Tea",
    image: '/image/prwmiumcc.png',
    cName: 'nav-text'
  },
  {
    id: 11,
    categoryId: 3,
    category: 'Deepzi Facewase',
    title: "Facewase",
    image: '/image/FacrewaseProduct.jpg',
    cName: 'nav-text'
  },

];


export const filterCat = [
  {
    id: 1,
    category: 'Deepzi Premium',
    image: '/image/ic7.svg',
    cName: 'nav-text'
  },
  {
    id: 2,
    category: 'Deepzi Gold',
    image: '/image/ic8.svg',
    cName: 'nav-text'
  },
  {
    id: 3,
    category: 'Deepzi Facewase',
    image: '/image/ic2.svg',
    cName: 'nav-text'
  },
  

]