import React from 'react'
import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Nav from '../Navbar/Nav';
import './header.css'



function Header() {
    const [applyStyles, setApplyStyles] = useState(true);
    const Location = useLocation()
  

    console.log(Location)
    return (
        <section>
            <div className='' >
                <div className='md:hidden  '><Nav></Nav></div>
                {/* <div className='bg-[#007C41]   grid grid-cols-10     h-[80px]'> */}
                <div className={` grid grid-cols-10 sticky top-0     h-[80px]  ${Location.pathname === "/Franchise" ? "md:bg-transparent md:absolute xs:bg-[#007C41] text-black" : "bg-[#007C41]"}`}>
                    <img className='col-span-2 w-[140px] xs:mt-6  xs:ml-6 mx-20 mt-2' src='image/deepzi logo.svg'></img>
                    <ul class="flex col-span-8 xs:hidden justify-center gap-10">
                        <NavLink to="/" className="font-myFont  px-3 py-[25px] text-white duration-700   text-lg">Home</NavLink>
                        <NavLink to="/About" className="font-myFont px-3 py-7 text-white  duration-700   text-lg">About us</NavLink>
                        <NavLink to="/Product1" className="font-myFont px-3 py-7 text-white duration-700    text-lg">Products</NavLink>
                        <NavLink to="/Franchise" className="font-myFont px-3 py-7 text-white duration-700    text-lg">Franchise</NavLink>
                        <NavLink to="/Gallery" className="font-myFont px-3 py-7 text-white duration-700    text-lg">Gallery</NavLink>
                        <NavLink to="/Contact" className="font-myFont px-3 py-7 text-white duration-700    text-lg">Contact us</NavLink>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Header