import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import Fade from 'react-reveal'


function About() {
    return (
        <div>
            {/* banner image area */}
            <div className='w-full h-[380px]' style={{ backgroundImage: 'url("./image/abtdeepzi.jpg")' }} >
                <h1 className='text-5xl font-myFont text-white pt-40 pl-10 font-bold xs:text-center'>About Us</h1>
            </div>
            <div className='h-20  ' style={{ backgroundImage: 'url("./image/allprodcut.svg")' }} >
                <h1 className='text-3xl p-4 font-serif text-black font-bold text-center'>About us</h1>
            </div>

            <section>
                <div className=' mt-10 xs:grid-cols-1 grid grid-cols-6'>
                    <Fade bottom >   
                    <div className='col-span-2'>
                        <img src='image/1about.svg'>
                        </img>
                    </div>
                    </Fade>
                    <Fade right>
                    <div className='xs:mt-6 mt-32 col-span-4'>
                        <h1 className='font-myFont font-bold ml-28 text-5xl'>Our Story</h1>
                        <div className='border-2 border-red-700 w-80 mt-4 '></div>
                        <h1 className='font-myFont mt-4 xs:mx-3 mr-40 ml-28 text-lg'>The brands philosophy is deep entrenched in the passion for excellence pursued by company founder. under the name of "Deepzi Herbal (opc) Pvt. Ltd." having its head office at Daregaon district - Jalna. Maharastra engaged in manufacturing and selling of Deepzi premium green tea and coffee.</h1>
                    </div>
                    </Fade>
                </div>
            </section>
            <section>
                <div className=' xs:mt-0 mt-10 xs:grid-cols-1 grid grid-cols-6'>

                 <Fade top>
                 <div className=' mt-32 col-span-4'>
                        <h1 className='font-myFont font-bold ml-28 text-5xl'>Our Journey</h1>
                        <div className='border-2 border-red-700 w-80 mt-4 '></div>
                        <h1 className='font-myFont mt-4 xs:mx-3 mr-40 ml-28 text-lg'>Each batch of tea sold under the Deepzi brand goes through an exhaustive process of selection from teas originating from over thousands tea estates and samples running into thousands every week. Carefully assesesed for their liquor, colour, body & aroma, each batch is personally selected & blended by our Master Tea Tastser.</h1>
                    </div>
                 </Fade>
                 <Fade left>
                 <div className='col-span-2'>
                        <img src='image/2about.jpg'>
                        </img>
                    </div>
                 </Fade>

                </div>
            </section>
            <section>
                <div className=' xs:mt-28 mt-10 xs:grid-cols-1 grid grid-cols-6'>
                   <Fade bottom>
                   <div className='col-span-2'>
                        <img src='image/3about.jpg'>
                        </img>
                    </div>
                   </Fade>
                   <Fade right>
                   <div className=' xs:mt-6 mt-32 col-span-4'>
                        <h1 className='font-myFont font-bold ml-28 text-5xl'>Our Mission</h1>
                        <div className='border-2 border-red-900 w-80 mt-4 '></div>
                        <h1 className='font-myFont mt-4 xs:mx-3 mr-40 ml-28 text-lg'>"Times change but our values don’t. With decades of experience, we have gathered enough knowledge to pick up all the nuances of coffee cultivation, selective picking, processing, and blending. In this way, we continue to conserve the legacy passed on to us, the results of which can be seen and tasted in every single bean."</h1>
                    </div>
                   </Fade>
                </div>
            </section>

            {/* team area */}

            <section>
                <Fade bottom cascade>
                <div className='h-[800px] xs:h-auto'>
                    <div > <h1 className=' text-center mt-16 font-[sans] text-[#E62929] text-[52px]'>Team <span className='text-black'>we have </span></h1>
                        <h2 className='  text-center font-[sans] text-[#000000] text-[30px]'>We have awesome team member to help support.</h2>
                    </div>
                    <div className=' mt-20 mx-60 grid grid-cols-3 xs:grid-cols-1 xs:mx-12'>
                        <div className='border-r-2 xs:border-hidden border-[#abb0bc] w-[260px] h-[450px]' ><img className='p-5' src='image/deepakChAuhan.png'></img><br></br>
                            <div className='w-[230px] bg-[#000000] h-[70px] rounded-md'>
                                <h2 className=' pt-2 font-medium text-[20px] text-center text-white'>
                                    Deepak Chauhan
                                </h2>
                                <h3 className='   text-center text-white'>
                                    Director
                                </h3>
                            </div>
                            <p className=' py-2 w-[230px] font-light text-center px-4'>
                                At Deepzi , we are committed to providing our customers with the highest quality products and services.
                            </p>
                            <div className='flex gap-4 justify-center pt-2 '>
                                <img src='image/fbsoc.svg'></img>
                                <img src='image/instsoc.svg'></img>
                                <img src='image/insttwit.svg'></img>
                            </div>
                        </div>
                        <div className='border-r-2 xs:border-hidden border-[#abb0bc] w-[260px] h-[450px]' ><img className='p-5' src='image/Group 33.png'></img><br></br>
                            <div className='w-[230px] bg-[#000000] h-[70px] rounded-md'>
                                <h2 className=' pt-2 font-medium text-[20px] text-center text-white'>
                                Rakesh Kumar
                                </h2>
                                <h3 className='   text-center text-white'>
                                    Staff
                                </h3>
                            </div>
                            <p className=' py-2 w-[230px] font-light text-center px-4'>
                                We are constantly innovating and improving to stay ahead and provide the best possible solutions for our customers.
                            </p>
                            <div className='flex gap-4 justify-center pt-2 '>
                                <img src='image/fbsoc.svg'></img>
                                <img src='image/instsoc.svg'></img>
                                <img src='image/insttwit.svg'></img>
                            </div>
                        </div>
                        <div className='border-r-2 xs:border-hidden border-[#abb0bc] w-[260px] h-[450px]' ><img className='p-5' src='image/Group 34.png'></img><br></br>
                            <div className='w-[230px] bg-[#000000] h-[70px] rounded-md'>
                                <h2 className=' pt-2 font-medium text-[20px] text-center text-white'>
                                  Mukkesh singh
                                </h2>
                                <h3 className='   text-center text-white'>
                                Staff
                                </h3>
                            </div>
                            <p className=' py-2 w-[230px] font-light text-center px-4'>
                                Our focuses on providing customers  Organic and Fair Trade Tea.
                            </p>
                            <div className='flex gap-4 justify-center pt-2 '>
                                <img src='image/fbsoc.svg'></img>
                                <img src='image/instsoc.svg'></img>
                                <img src='image/insttwit.svg'></img>
                            </div>
                        </div>

                    </div>
                </div>
                </Fade>
            </section>
            <section>
                <div className='grid grid-cols-2 xs:mx-0 xs:grid-cols-1 bg-gradient-to-r from-green-400 to-blue-500  mx-40 h-32 xs:h-60'>
                    <div>
                        <h2 className='text-center pt-12 text-white font-serif  font-semibold text-3xl'>Your Comfort is Our Priority</h2>
                    </div>
                    <div className='pt-10 mx-20 '>
                        <NavLink to="/Product1" >  <button className="h-12 xs:px-14 rounded-[50px] bg-white  right-0 px-20 text-lg font-medium text-black duration-300 hover:bg-orange-300 ">Visit Shop</button></NavLink>                    </div>
                </div>
            </section>
        </div>
    )
}

export default About