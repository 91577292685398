import React from "react";
import { useState } from "react";
import { Categories } from "./Categories";
import ReactPaginate from "react-paginate";
import { filterCat } from "./Categories";
import { send } from "emailjs-com";



// import {
//     Button,
//     Dialog,
//     DialogHeader,
//     DialogBody,
//     DialogFooter,
//   } from "@material-tailwind/react";

//   import React from 'react';
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from '@mui/material/Button';
// import { ToastContainer } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Category() {
  // const [open, setOpen] = useState(false);
  const [data, setData] = useState(Categories);
  const [pageNumber, setPageNumber] = useState(0);
  const [active, setActive] = useState("");
  const [buttonPopup, setButtonPopup] = useState(false);
  const [text, setText] = useState("Your choice is here!");
  const [open, setOpen] = React.useState(false);
  const [validateFormName, setValidateFormName] = React.useState(true);
  const [validateToName, setValidateToName] = React.useState(true);
  const [validateFormMassage, setValidateFormMassage] = React.useState(true);
  const [validateFormReplyTo, setValidateFormReplyTo] = React.useState(true);
  const [showSuccess, setShowSuccess] = useState(false);

  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const [toSend, setToSend] = useState({
    from_name: "",
    to_name: "",
    message: "",
    reply_to: "",
  });

  // const handleOpen = () => setOpen(!open);

  //   const name = Categories.category

  // const FilterClick = (catitem) => {
  //     const result = Categories.filter((curData) => {
  //         return curData.category === catitem;
  //     });
  //     setData(result)
  //     // setActive(Categories.id)
  // }

  const handleClickOpen = (title) => {
    setToSend({ ...toSend, to_name: title });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    // return Object.values(toSend).every(item => item)
    if (toSend.from_name) {
      setValidateFormName(true);
    } else {
      setValidateFormName(false);
    }
    if (toSend.to_name) {
      setValidateToName(true);
    } else {
      setValidateToName(false);
    }
    if (toSend.message) {
      setValidateFormMassage(true);
    } else {
      setValidateFormMassage(false);
    }
    if (toSend.reply_to) {
      setValidateFormReplyTo(true);
    } else {
      setValidateFormReplyTo(false);
    }
  };

  const validateFormInputs = () => {
    validateForm();
    return (
      toSend.to_name && toSend.from_name && toSend.message && toSend.reply_to
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let isValidate = validateFormInputs();
    if (isValidate) {
      // setIsLoading(true);
      send("service_xr65myj", "template_z1lshfj", toSend, "PGGK4pIssPbgfua7i")
        .then((response) => {
          console.log("SUCCESS!", response.status, response.text);
          toast.success('Congratulation Order Placed successfully!');
          // setShowSuccess(true);
        })
        .catch((err) => {
          console.log("FAILED...", err);
          toast.error('Something went wrong. Please try again later.');
          // setShowError(true);
        });
    }
  };

  function alertClose() {
    setShowSuccess(false);
    setShowError(false);
  }


  const usersPerPage = 40;
  const pagesVisited = pageNumber * usersPerPage;

  const pageCount = Math.ceil(data.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayProduct = data
    .slice(pagesVisited, pagesVisited + usersPerPage)
    .map((value, index) => {
      const { id, title, image } = value;
      if (value.categoryId === active || !active)
        return (
          <div className="inline-grid">
            <div className=" inline-flex xs:ml-0 ml-6 mt-6 ">
              <div
                key={id}
                className=" inline-grid relative  overflow-hidden bg-cover  bg-white xs:my-1 mx-1 my-4  xs:w-[170px] border h-fit w-[180px] "
              >
                <img className="" src={image} alt="product img" />
                <div className="px-6 xs:p-2   py-3">
                  <div className="font-semibold text-center xs:text-[14px] text-sm mb-2">
                    {title}
                  </div>
                </div>
                <button
                  className="absolute top-[0px] text-white text-md right-0 font-bold h-full w-full overflow-hidden  bg-[rgba(0,0,0,0.6)] opacity-0 transition duration-300 ease-in-out hover:opacity-100"
                  onClick={() => handleClickOpen(title)}
                >
                  {" "}
                  PLACE ORDER NOW
                </button>
              </div>
            </div>
         
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle className="text-[#007C41]  text-center">
                PLACE ORDER NOW
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  For order food please fill up these details Which is given
                  below
                </DialogContentText>
                {!validateFormName && (
                  <p className="text-red-600">Please Enter full name</p>
                )}

                <TextField
                  autoFocus
                  margin="dense"
                  // id="name"
                  label="Full Name"
                  fullWidth
                  // variant="standard"
                  type="text"
                  name="from_name"
                  placeholder="Enter Your Name"
                  value={toSend.from_name}
                  onChange={handleChange}
                  required
                />

                <TextField
                  autoFocus
                  margin="dense"
                  // id="name"
                  label="Product Name"
                  fullWidth
                  //   variant="standard"
                  type="text"
                  name="to_name"
                  placeholder="Enter Product Name"
                  value={toSend.to_name}
                  onChange={handleChange}
                  disabled
                  required
                />
                {!validateFormMassage && (
                  <p className="text-red-600">Please Enter quantity</p>
                )}
                <TextField
                  autoFocus
                  margin="dense"
                  // id="name"
                  label="Quantity"
                  fullWidth
                  //   variant="standard"
                  type="number"
                  name="message"
                  placeholder="Enter Quantity"
                  value={toSend.message}
                  onChange={handleChange}
                  required
                />
                {!validateFormReplyTo && (
                  <p className="text-red-600">Please Enter your address</p>
                )}
                <TextField
                  autoFocus
                  margin="dense"
                  // id="name"
                  label="Delivery Address With pin code"
                  fullWidth
                  //   variant="standard"

                  type="text"
                  name="reply_to"
                  placeholder="Enter Your Delivery Address With pin code"
                  value={toSend.reply_to}
                  onChange={handleChange}
                  required
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" color="success" onClick={onSubmit}>
                  Place Order
                </Button>
              </DialogActions>
            </Dialog>

            <ToastContainer />
          </div>
        );
    });

  return (
    <div className="">
      <div
        className="w-full xs:hidden h-[380px]"
        style={{ backgroundImage: 'url("./image/prodtdeepzi.jpg")' }}
      >
        <h1 className="text-5xl font-myFont text-white pt-40 pl-10 font-bold xs:text-center">
          Our Product's
        </h1>
      </div>
      <div
        className="mx-2 md:hidden my-2 "
      // style={{ backgroundImage: 'url("./image/productupdatedeep.svg")' }}
      >
        <img src="./image/productupdatedeep.svg"></img>
      </div>
      <div
        className="h-20  "
        style={{ backgroundImage: 'url("./image/allprodcut.svg")' }}
      >
        <h1 className="text-3xl p-4 font-serif text-black font-bold text-center">
          Our Delicious menu
        </h1>
      </div>
      <section>
        <div className=" xs:grid-cols-1 grid grid-cols-9">
          <div className="   col-span-2">
            <div className="border xs:hidden bg-white">
              <h1 className="text-2xl xs:text-sm p-6 font-medium">
                Filter Category
              </h1>
            </div>

            <div className="bg-white md:border xs:bg-[F1F1F1] xs:flex scrollbar-thin   scrollbar-thumb-gray-100 xs:h-[90px]  h-[400px] p-4">
              {filterCat.map((item, index) => {
                return (
                  <div
                    className={`flex gap-4 font-medium  hover:text-[#04aa6b] xs:border-2 xs:p-1 xs:mx-1 xs:rounded-[7px]	 cursor-pointer p-3 ${active == item.id && "text-[#04aa6b] xs:shadow-md "
                      }`}
                    onClick={() => setActive(item.id)}
                  >
                    <img className="w-14  h-10" src={item.image}></img>
                    <span className=" xs:text-sm text-lg">
                      {" "}
                      {item.category}{" "}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-span-7 xs:col-span-6">
            <div>
              <section>
                <div>
                  <h1 className="text-center xs:hidden font-myFont text-[#082161] text-4xl pt-10">
                    {text}
                  </h1>
                </div>
                <div>
                  {displayProduct}
                  <div className="pagi ">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      pageCount={pageCount}
                      onPageChange={changePage}
                      containerClassName={"paginationBttns"}
                      previousLinkClassName={"previousBttn"}
                      nextLinkClassName={"nextBttn"}
                      disabledClassName={"paginationDisabled"}
                      activeClassName={"paginationActive"}
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Category;
