import React, {useState} from 'react'
import Home from '../Home/Home'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import { BrowserRouter, Route, Routes,   } from 'react-router-dom'
import About from '../About/About'
import Contact from '../Contact/Contact'
import Franchise from '../Franchise/Franchise'
import Gallery from '../Gallery/Gallery'
import Product1 from '../product 1/Product1'
import Nav from '../Navbar/Nav'

function Page() {
    const [applyStyles, setApplyStyles] = useState(true);

  
    
    return (
        <div><BrowserRouter>
            <div className=''><Header  />     </div>
       
            <Routes  >
                <Route exact path="/" element={<Home />} />
                <Route exact path="/About" element={<About />} />
                <Route exact path="/Contact" element={<Contact />} />
                <Route exact path="/Contact" element={<Contact />} />
                <Route exact path="/Franchise" element={<Franchise />} />
                <Route exact path="/Gallery" element={<Gallery />} />
                <Route exact path="/Product1" element={<Product1 />} />
            </Routes>
            <Footer />
        </BrowserRouter></div>
    )
}

export default Page