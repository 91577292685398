import React from 'react'

function Franchise() {
    return (
        <div>

            <div ><img className='xs:hidden w-full ' src='image/franchiss.svg'></img>
                <img className='md:hidden' src='image/mobileFranchise.svg'></img>
            </div>
            <section>
                <div className=' md:ml-60 mt-20 flex gap-6'>
                    <img className='xs:hidden' src='image/iconee1.svg'></img>
                    <h1 className='text-center font-myFont text-4xl font-bold'>Why You Choose Deepzi As Best Cafe Franchise</h1>
                    <img className='xs:hidden' src='image/iconee2.svg'></img>
                </div>
                <div className='grid xs:grid-cols-1 xs:ml-2 gap-4 mt-20 grid-cols-3'>
                    <div className='col-span-1  hover:shadow-xl cursor-pointer hover:scale-110 transition duration-500 ease-in-out  md:ml-40 w-[340px] h-48 rounded-lg bg-white shadow-lg '>
                        <img className='py-4 ml-32 mt-4 ' src='image/menu.png'></img>
                        <p className=' px-8  text-lg text-center'>Scrumptious Menu with Pocket-Friendly Pricing</p>
                    </div>
                    <div className='col-span-1 hover:shadow-xl cursor-pointer hover:scale-110 transition duration-500 ease-in-out  md:mx-20  w-[340px] h-48 rounded-lg bg-white shadow-lg '>
                        <img className='py-4 ml-32 mt-4 ' src='image/roi.png'></img>
                        <p className=' px-8  text-lg text-center'>High ROI (Return on Investment)</p>
                    </div>
                    <div className='col-span-1 hover:shadow-xl cursor-pointer hover:scale-110 transition duration-500 ease-in-out  w-[340px] h-48 rounded-lg bg-white shadow-lg '>
                        <img className='py-4 ml-32 mt-4 ' src='image/training.png'></img>
                        <p className=' px-8  text-lg text-center'>We provide Training to Staff members</p>
                    </div>
                    <div className='col-span-1 hover:shadow-xl cursor-pointer hover:scale-110 transition duration-500 ease-in-out md:ml-40 w-[340px] h-48 rounded-lg bg-white shadow-lg '>
                        <img className='py-4 ml-32 mt-4 ' src='image/brand.png'></img>
                        <p className=' px-8  text-lg text-center'>Well- Known Brand</p>
                    </div>
                    <div className='col-span-1 hover:shadow-xl cursor-pointer hover:scale-110 transition duration-500 ease-in-out md:mx-20  w-[340px] h-48 rounded-lg bg-white shadow-lg '>
                        <img className='py-4 ml-32 mt-4 ' src='image/model.png'></img>
                        <p className=' px-8  text-lg text-center'>Proven Business Model</p>
                    </div>
                    <div className='col-span-1  hover:shadow-xl cursor-pointer hover:scale-110 transition duration-500 ease-in-out  w-[340px] h-48 rounded-lg bg-white shadow-lg '>
                        <img className='py-4 ml-32 mt-4 ' src='image/taste.png'></img>
                        <p className=' px-8  text-lg text-center'>We offer Hygienic & Standard Tasty Tea</p>
                    </div>
                </div>
            </section>
            <section>
                <div className='grid  bg-red-700 mt-32 xs:mt-10 xs:grid-cols-1 grid-cols-2'>
                    <div className='mx-12 my-16 col-span-1'>
                        <img className='rounded-lg xs:hidden' src='image/cafeImage.svg'>
                        </img>
                    </div>
                    <div className=' xs:-mt-24 col-span-1'>
                        <h1 className='text-center font-bold text-5xl font-myFont text-white mt-10'> Our Franchise Model</h1>
                        <h3 className='text-white xs:mx-4 font-serif mt-4 text-2xl'>Kiyosk Modal :- <span className='text-lg font-sans'>Kiosk franchises offer you the unique opportunity to be in business for yourself, but not by yourself. With proven business models in place and support from trained and knowledgeable franchisors.</span> </h3>
                        <div className='grid xs:my-8 gap-4 mt-8 xs:grid-cols-1 xs:ml-4 grid-cols-2'>
                            <div className='col-span-1 cursor-pointer  hover:scale-110 transition duration-500 ease-in-out  w-[320px] h-52 rounded-xl bg-white shadow-lg '>
                                <img className='py-4 ml-32 mt-1 ' src='image/taste.png'></img>
                                <h2 className=' px-8 font-serif text-red-900 text-2xl text-center'>INVESTMENT</h2>
                                <p className='text-center mt-3 font-medium'>You nees only 10 lac for Investment</p>
                            </div>
                            <div className='col-span-1 cursor-pointer hover:scale-110 transition duration-500 ease-in-out  w-[320px] h-52 rounded-xl bg-white shadow-lg '>
                                <img className='py-4 ml-32 mt-1 ' src='image/taste.png'></img>
                                <h2 className=' px-8 font-serif text-red-900 text-2xl text-center'>PROFIT</h2>
                                <p className='text-center mt-3 font-medium'>You get 3% of  from  profit by franchise</p>
                            </div>
                            <div className='col-span-1 cursor-pointer hover:scale-110 transition duration-500 ease-in-out   w-[320px] h-52 rounded-xl bg-white shadow-lg '>
                                <img className='py-4 ml-32 mt-1 ' src='image/taste.png'></img>
                                <h2 className=' px-8 font-serif text-red-900 text-2xl text-center'>AREA REQUIRED</h2>
                                <p className='text-center mx-4 mt-3 font-medium'>You should have 200 - 500 square feet area</p>
                            </div>
                            <div className='col-span-1 cursor-pointer hover:scale-110 transition duration-500 ease-in-out   w-[320px] h-52 rounded-xl bg-white shadow-lg '>
                                <img className='py-4 ml-32 mt-1 ' src='image/taste.png'></img>
                                <h2 className=' px-8 font-serif text-red-900 text-2xl text-center'>SALARY </h2>
                                <p className='text-center mx-4 mt-3 font-medium'>You will get sellary as per franchise term</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='grid mx-8 mt-10 xs:grid-cols-1 grid-cols-2'>
                <div className='col-span-1'>
                    <img className='rounded-lg' src='image/franchise2.jpeg'>
                    </img>
                </div>
                <div className=' col-span-1'>

                    <h1 className='ml-48 xs:ml-10 xs:text-center xs:text-[40px] text-[55px] font-myFont text-red-800'>Get a Franchise</h1>
                    <form class="w-full xs:ml-1 mt-4 mx-28 max-w-lg">
                        <div class="flex  flex-wrap -mx-3 mb-6">
                            <div class="w-full xs:mb-2 md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                    Your Name
                                </label>
                                <input class="appearance-none block w-full bg-gray-100 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Enter Name" />
                            </div>
                            <div class="w-full xs:mb-5 md:w-1/2 px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                    Your Email
                                </label>
                                <input class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="email" placeholder="Enter Email" />
                            </div>
                            <div class="w-full xs:mb-2 md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    Contact Number
                                </label>
                                <input class="appearance-none block w-full bg-gray-100 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="number" placeholder="Enter Number" />

                            </div>
                            <div class="w-full xs:mb-5 md:w-1/2 px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    City
                                </label>
                                <input class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="City Name" />
                            </div>
                            <div class="w-full xs:mb-2 md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                    Pin Code
                                </label>
                                <input class="appearance-none block w-full bg-gray-100 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Enter Pin code" />

                            </div>
                            <div class="w-full  xs:mb-4 md:w-1/2 px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                    Area
                                </label>
                                <input class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Address in which you want to open deepzi outlet" />
                            </div>
                        </div>
                        <div class="flex flex-wrap -mt-4 -mx-3 ">
                            <div class="w-full px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                    Massage
                                </label>
                                <textarea class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Enter Massage" />
                            </div>
                        </div>
                        <button className='  mt-4 h-10 bg-[#D9D9D9] text-black font-myFont text-lg font-bold hover:bg-red-800 hover:text-white border-2 w-full'>Send</button>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default Franchise