import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Nav = () => {
  const [nav, setNav] = useState(false);

  return (
    <div>
      <div onClick={() => setNav(!nav)}>
        <img
          className="absolute mt-8 right-4 h-6 w-6 cursor-pointer "
          src="image/sideicone.svg"
        ></img>
      </div>
      <div
        className={
          nav
            ? "fixed top-0 left-0 w-[320px] h-full bg-white z-10 duration-500"
            : "fixed top-0 left-[-100%] w-[320px] h-screen bg-white z-10 duration-500"
        }
      >
        <div onClick={() => setNav(!nav)}>
          <img
            className="absolute mt-8 right-6 h-6 w-6 cursor-pointer "
            src="image/crosss.svg"
          ></img>
        </div>
        <nav>
          <div>
            {" "}
            <img className=" p-6 " src="image/deepzi logo.svg"></img>
          </div>
          <ul className="flex flex-col px-8 mt-8 text-gray-800">
            <li onClick={() => setNav(!nav)} className="text-xl py-2 flex ">
              {" "}
              <NavLink  className="pr-44 rounded-lg pl-4 py-4" to="/">
                {" "}
                Home{" "}
              </NavLink>{" "}
            </li>
            <li onClick={() => setNav(!nav)} className="text-xl py-1 flex ">
              {" "}
              <NavLink className="pr-44 rounded-lg pl-4 py-4" to="/About">
                {" "}
                About{" "}
              </NavLink>{" "}
            </li>
            <li onClick={() => setNav(!nav)} className="text-xl py-1 flex">
              {" "}
              <NavLink className="pr-44 rounded-lg pl-4 py-4" to="/Product1">
                {" "}
                Products{" "}
              </NavLink>{" "}
            </li>
            <li onClick={() => setNav(!nav)} className="text-xl py-1 flex">
              {" "}
              <NavLink className="pr-44 rounded-lg pl-4 py-4" to="/Franchise">
                {" "}
                Franchise{" "}
              </NavLink>{" "}
            </li>
            <li onClick={() => setNav(!nav)} className="text-xl py-1 flex">
              {" "}
              <NavLink className="pr-44 rounded-lg pl-4 py-4" to="/Gallery">
                {" "}
                Gallery{" "}
              </NavLink>{" "}
            </li>
            <li onClick={() => setNav(!nav)} className="text-xl py-1 flex">
              {" "}
              <NavLink className="pr-44 rounded-lg pl-4 py-4" to="/Contact">
                {" "}
                Contact{" "}
              </NavLink>{" "}
            </li>
          </ul>
        </nav>
        <div className="flex gap-6 pl-10 pt-6">
          <img src="image/fbsoc.svg"></img>
          <img src="image/instsoc.svg"></img>
          <img src="image/insttwit.svg"></img>
        </div>
      </div>
    </div>
  );
};

export default Nav;
