import React from 'react'
import CountUp from 'react-countup';
import { Fade } from 'react-reveal';
import { NavLink } from 'react-router-dom';
import Carousel from '../Carousel/Carousel';
import RSlider from '../ResponsivesSlider/RSlider';
import Slider from '../Slider/Slider';


function Home() {
    return (
        <div>

            {/* banner iamge area */}

            {/* <div className='w-full h-[600px]  bg-[#007C41] rounded-br-[160px] rounded-bl-[160px]' style={{ backgroundImage: 'url("./image/bannhomed.svg")' }} >
                <h1 className='text-5xl xs:pt-10 font-myFont text-white pt-28 pl-28 font-bold xs:pl-16 '>Time To Discover </h1>
                <h1 className='text-5xl font-myFont text-white pl-28 pt-6 font-bold xs:pl-16 '> Flavours House</h1>
                <p className='text-lg  font-myFont text-white pl-28 pt-6 font-sm xs:pl-16 '> Everything we do is a matter of heart, body and soul.<br />
                    Our mission is to provide sustainably sourced, hand-picked, quality coffee</p>
                <div>
                    <NavLink to="/Product1" >  <button className=' xs:w-52 xs:ml-16 ml-28 mt-10 h-10 bg-transparent border-2 text-white  font-myFont text-lg font-bold hover:bg-black hover:text-white  w-[380px]'>Explore More</button></NavLink>
                </div>
            </div> */}
            <div className='w-full h-[600px] xs:hidden  bg-[#007C41] rounded-br-[160px] rounded-bl-[160px]' >
                {/* <h1 className='text-5xl xs:pt-10 font-myFont text-white pt-28 pl-28 font-bold xs:pl-16 '>Time To Discover </h1>
                <h1 className='text-5xl font-myFont text-white pl-28 pt-6 font-bold xs:pl-16 '> Flavours House</h1>
                <p className='text-lg  font-myFont text-white pl-28 pt-6 font-sm xs:pl-16 '> Everything we do is a matter of heart, body and soul.<br />
                    Our mission is to provide sustainably sourced, hand-picked, quality coffee</p>
                <div>
                    <NavLink to="/Product1" >  <button className=' xs:w-52 xs:ml-16 ml-28 mt-10 h-10 bg-transparent border-2 text-white  font-myFont text-lg font-bold hover:bg-black hover:text-white  w-[380px]'>Explore More</button></NavLink>
                </div> */}
                <Slider />
            </div>
            <div className=' md:hidden lg:hidden mt-0 '>
                <RSlider></RSlider>
            </div>

            <div className='grid grid-cols-6 relative xs:mx-[36px] xs:-mt-8 -mt-20 bg-white border xs:py-5 xs:px-6 py-12 px-20 gap-20 xs:gap-10 mx-[300px] border-green-600 rounded-lg '>
                <div className='col-span-2  my-auto'>
                    <div className=' text-6xl xs:text-3xl  font-bold font-myFont gap-2  justify-center flex'>
                        <CountUp className='' end={50}
                            duration={2}
                        /><h2>+</h2>
                    </div>
                    <h4 className='text-lg xs:text-xs font-bold font-myFont gap-2 text-red-800 text-center'>TOTAL COFFEE</h4>
                </div>
                <div className='col-span-2 my-auto'>
                    <div className=' text-6xl xs:text-3xl font-bold font-myFont gap-2  justify-center flex'>
                        <CountUp className='' end={150}
                            duration={2}
                        /><h2>+</h2>
                    </div>
                    <h4 className='text-lg xs:text-xs font-bold font-myFont gap-2 text-red-800 text-center'>TOTAL GREEN TEA</h4>
                </div>
                <div className='col-span-2  my-auto'>
                    <div className=' text-6xl xs:text-3xl font-bold font-myFont gap-2  justify-center flex'>
                        <CountUp className='' end={76}
                            duration={2}
                        /><h2>+</h2>
                    </div>
                    <h4 className='text-lg xs:text-xs font-bold font-myFont gap-2 text-red-800 text-center'>TOTAL TEA</h4>
                </div>
            </div>


            {/* carousel area */}

            <section className='mt-6' >
                <div className='flex mb-4 items-center justify-center'> <img className='' src='image/cofeeicon.svg'></img></div>
                <h1 className='text-center mb-6 font-myFont text-5xl text-[#964315]'>Our Popular Item</h1>
                <div><Carousel /></div>

            </section>

            {/* popular menu are */}

            <section>
                <div className='h-[700px] xs:h-[2300px] mt-20 ' style={{ backgroundImage: "url('/image/popularmenu.png')" }}>
                    <h1 className='text-center xs:text-4xl  pt-10 font-myFont text-5xl text-white'>Our Popular Menu</h1>
                    <div className='grid grid-cols-4 xs:-mt-8 xs:grid-cols-1'>
                        <Fade bottom >
                            <div className='mx-auto mt-32 ' >
                                <div className='bg-white hover:scale-105 duration-500 cursor-pointer rounded-md h-[410px] w-[270px]'>
                                    <img className='mx-auto' src='image/premiumImages.jpeg'></img>
                                    <h1 className='text-center my-4 text-2xl font-myFont '>Deepzi Premium</h1>
                                    <h3 className='text-center mx-2 my-4 font-myFont '>It is produced by grinding roasted coffee beans, then boiling them.</h3></div>
                            </div>
                        </Fade>
                        <Fade bottom>
                            <div className='mx-auto mt-32 ' >
                                <div className='bg-white hover:scale-105 duration-500 cursor-pointer rounded-md h-[410px] w-[270px]'>
                                    <img className='mx-auto' src='image/golddeep.jpg'></img>
                                    <h1 className='text-center my-4 text-2xl font-myFont '>Deepzi Gold</h1>
                                    <h3 className='text-center mx-2 my-4 font-myFont '>It is produced by grinding roasted coffee beans, then boiling them.</h3></div>
                            </div>
                        </Fade>
                        <Fade bottom>
                            <div className='mx-auto mt-32 ' >
                                <div className='bg-white hover:scale-105 duration-500 cursor-pointer rounded-md h-[410px] w-[270px]'>
                                    <img className='mx-auto' src='image/Facrewase.jpg'></img>
                                    <h1 className='text-center my-4 text-2xl font-myFont '>Deeptal Facewase</h1>
                                    <h3 className='text-center mx-2 my-4 font-myFont '>It is produced by grinding roasted coffee beans, then boiling them.</h3></div>
                            </div>
                        </Fade>
                        <Fade bottom>
                            <div className='mx-auto mt-32 ' >
                                <div className='bg-white hover:scale-105 duration-500 cursor-pointer rounded-md h-[410px] w-[270px]'>
                                    <img className='mx-auto' src='image/Chocolate Tea.svg'></img>
                                    <h1 className='text-center my-4 text-2xl font-myFont '>Chocolate Tea</h1>
                                    <h3 className='text-center mx-2 my-4 font-myFont '>It is produced by grinding roasted coffee beans, then boiling them.</h3></div>
                            </div>
                        </Fade>
                    </div>

                </div>
            </section>


            {/* shop gallery are */}

            <section>

                <div className='   mt-20 ' >
                    <h1 className='text-center xs:pb-10 pt-4 font-myFont text-5xl text-red-800'>Our Shop Gallery</h1>
                    <div className=' mx-32 xs:mx-auto grid grid-cols-4 xs:grid-cols-1'>

                        <div className='mx-auto hover:scale-105 duration-500  mt-32 xs:mt-4 cursor-pointer  ' >
                            <div className='bg-white rounded-md h-[410px] w-[270px]'>
                                <img className='mx-auto' src='image/deepcoffee.svg'></img>

                            </div>
                        </div>
                        <div className='mx-auto hover:scale-105 duration-500 cursor-pointer mt-32 xs:mt-0 ' >
                            <div className='bg-white rounded-md h-[410px] w-[270px]'>
                            <img className='mx-auto' src='image/facewasegallery.jpg'></img>
                              
                            </div>                        </div>
                        <div className='mx-auto hover:scale-105 duration-500 cursor-pointer mt-32 xs:mt-0' >
                            <div className='bg-white rounded-md h-[410px] w-[270px]'>
                                <img className='mx-auto' src='image/3shop.jpg'></img>
                            </div>
                        </div>
                        <div className='mx-auto hover:scale-105 duration-500 cursor-pointer mt-32 xs:mt-0' >
                            <div className='bg-white rounded-md h-[410px] w-[270px]'>
                                <img className='mx-auto' src='image/4shop.jpg'></img>
                            </div>
                        </div>
                        <div className='mx-auto hover:scale-105 duration-500 cursor-pointer xs:mt-0' >
                            <div className='bg-white rounded-md h-[410px] w-[270px]'>
                                <img className='mx-auto' src='image/5shop.jpg'></img>
                            </div>
                        </div>
                        <div className='mx-auto hover:scale-105 duration-500 cursor-pointer  xs:mt-0' >
                            <div className='bg-white rounded-md h-[410px] w-[270px]'>
                                <img className='mx-auto' src='image/gallery6.png'></img>
                            </div>
                        </div>
                        <div className='mx-auto hover:scale-105 duration-500 cursor-pointer xs:mt-0 ' >
                            <div className='bg-white rounded-md h-[410px] w-[270px]'>
                                <img className='mx-auto' src='image/gallerymain2.png'></img>
                            </div>
                        </div>
                        <div className='mx-auto hover:scale-105 duration-500 cursor-pointer xs:mt-0 ' >
                            <div className='bg-white rounded-md h-[410px] w-[270px]'>
                            <img className='mx-auto' src='image/gallery2.png'></img>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {/* Testimonial area */}

            <section>
                <div className='  xs:h-[600px] top-0 bg-white  '>

                    <h1 className='text-center xs:text-4xl sm:text-4xl md:text-5xl font-bold text-[#000000] mt-2 '>Testimonial</h1>
                    <h2 className='text-center pt-3  font-light text-[#000000]  '>Our costumar says</h2>
                    <div className=' xs:grid-cols-1 xs:mx-2 mx-60 mt-10 md:flex  '>

                        <div className='xs:pl-20' >
                            <img src='image/1testiM.png'></img><br></br>
                            <span className=' xs:pl-12 pl-2 font-semibold '>Mr. Rahul  </span>
                        </div>
                        <div className='pl-20 xs:pl-1  flex'>
                            <img className='xs:hidden' src='image/Group 152 (3).svg'></img>
                            <span className='text-center xs:pt-8 xs:text-center xs:pl-1 py-20 pl-10 font-semibold '>I met a keen observer who gave me a tip: ‘If you run across a restaurant where you often see Those are two classes of people who like to eat well and get their money’s worth.</span>
                        </div>

                    </div>
                </div>
            </section>

            {/* our popular menu area */}

            <section>
                <div className=' mt-32  xs:mt-1  xs:ml-4  bg-white  '>
                    <h1 className='text-center xs:text-4xl sm:text-4xl md:text-5xl xs:mt-0 font-bold  mt-2 font-myFont text-red-800 '>Our Popular Menu</h1>
                    <div className=' xs:grid-cols-1 grid grid-cols-12 mx-60 xs:mx-1 xs:mt-10 gap-2 mt-28'>
                        <div className='  col-span-6'>
                            <div className='flex '><img src='image/coffee-1.jpg ' className='rounded-3xl' alt='pro'></img>
                                <h1 className=' pl-10  text-xl font-bold font-myFont'>Deepzi Gold <br /> <span className='text-sm text-slate-500'>Made with Best coffee beans sourced directly from farms.</span></h1></div>
                            <div className='flex mt-6 '><img src='image/coffee-3.jpg ' className='rounded-3xl' alt='pro'></img>
                                <h1 className=' pl-10 text-xl font-bold font-myFont'>Deepzi Premium <br /> <span className='text-sm text-slate-500'>Made with Best coffee beans sourced directly from farms.</span></h1></div>
                            <div className='flex mt-6 '><img src='image/instagram-4.jpg ' className='rounded-3xl' alt='pro'></img>
                                <h1 className=' pl-10 text-xl font-bold font-myFont'>Deepzi Honey Green Tea <spna className="text-sm text-red-500 ml-6" >Comming soon</spna> <br /> <span className='text-sm text-slate-500'>Made with Best coffee beans sourced directly from farms.</span></h1></div>
                        </div>
                        <div className='  col-span-6'>
                            <div className='flex '><img src='image/coffee-2.jpg ' className='rounded-3xl' alt='pro'></img>
                                <h1 className=' pl-10 text-xl font-bold font-myFont'>Deepzi Leamon Green Tea  <spna className="text-sm text-red-500 ml-6" >Comming soon</spna> <br /> <span className='text-sm text-slate-500'>Made with Best coffee beans sourced directly from farms.</span></h1></div>
                            <div className='flex mt-6 '><img src='image/instagram-3.jpg ' className='rounded-3xl' alt='pro'></img>
                                <h1 className=' pl-10 text-xl font-bold font-myFont'>Deepzi Green Tea Facewase <spna className="text-sm text-red-500 ml-6" >Comming soon</spna> <br /> <span className='text-sm text-slate-500'>Made with Best coffee beans sourced directly from farms.</span></h1></div>
                            <div className='flex mt-6 '><img src='image/instagram-2.jpg ' className='rounded-3xl' alt='pro'></img>
                                <h1 className=' pl-10 text-xl font-bold font-myFont'>Deepzi Chololate Tea <spna className="text-sm text-red-500 ml-6" >Comming soon</spna> <br /> <span className='text-sm text-slate-500'>Made with Best coffee beans sourced directly from farms.</span></h1></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Home